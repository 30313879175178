import './ficha_espectaculos.scss';

import { graphql } from 'gatsby';

import React from 'react';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import Buttons from '../src/components/Buttons/Buttons';
import { Freya } from '../src/components/Freya/Freya';
import H3 from '../src/components/Titles/TitleH3/TitleH3';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Layout from '../src/components/Layout/Layout';
import Loki from '../src/components/Loki/lokipane';
import Hod from '../src/components/Hod/Hod';
import { MagniLeft } from '../src/components/Magni/MagniLeft';
import { MagniRight } from '../src/components/Magni/MagniRight';
import Mimir from '../src/components/Mimir/Mimir';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import SEO from '../src/helpers/seo';
import { Saga } from '../src/components/Saga/Saga';
import tt from '../src/helpers/translation';

export const pageQuery = ({ data, pathContext: { locale } }) => graphql`
  query showQuery($show_name: String!, $locale: String!) {
    allShowsDetailsSeoData(filter: { tag: { eq: $show_name }, lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allShowsDetailsImageBlock(filter: { tag: { eq: $show_name }, lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allShowsDetailsHeaderBlock(filter: { tag: { eq: $show_name }, lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
        }
      }
    }
    allShowsDetailsMimirIcons(
      filter: { tag: { eq: $show_name }, lang: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          icon
          text
          text2
          text3
          text4
          superior
          inferior
        }
      }
    }
    allShowsDetailsImageHeaderBlock(filter: { tag: { eq: $show_name }, lang: { eq: $locale } }) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allShowsDetailsBreadCrumbBlock(filter: { tag: { eq: $show_name }, lang: { eq: $locale } }) {
      edges {
        node {
          tag
          destination_url
          name
        }
      }
    }
    allShowsDetailsMagniBlock(filter: { tag: { eq: $show_name }, lang: { eq: $locale } }) {
      edges {
        node {
          left_link_copy
          left_link_path
          right_link_copy
          right_link_path
        }
      }
    }
    allShowsDetailsSagaContent(filter: { tag: { eq: $show_name }, lang: { eq: $locale } }) {
      edges {
        node {
          tag
          title
          summary
        }
      }
    }
    allShowsDetailsSagaImage(
      filter: { tag: { eq: $show_name }, lang: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          image
        }
      }
    }
    allShowsDetailsRich(filter: { tag: { eq: $show_name }, lang: { eq: $locale } }) {
      edges {
        node {
          richmark
        }
      }
    }
    allShowsDetailsVideo(filter: { tag: { eq: $show_name }, lang: { eq: $locale } }) {
      edges {
        node {
          video
        }
      }
    }
    allShowsDetailsCta(filter: { tag: { eq: $show_name }, lang: { eq: $locale } }) {
      edges {
        node {
          ctaText
          cta
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
    allShowsDetailsLokiImages(filter: { tag: { eq: $show_name }, lang: { eq: $locale } }) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    bigLokiPics: allShowsDetailsLokiImages(
      filter: { tag: { eq: $show_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allShowsDetailsFreya(filter: { tag: { eq: $show_name }, lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imgsize
          title
          image_alt
          buttons {
            cta
            size
            color
            ctaText
          }
        }
      }
    }
    allShowsDetailsRichData(filter: { tag: { eq: $show_name }, lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            color_css_class
            og_description
            og_title
            twitter_description
            twitter_title
            date_valid_until
            date_valid_start
            rich_markup_type
            phone
            imgRich
          }
        }
      }
    }
  }
`;

class showDetails extends React.Component {
  state = {};

  render() {
    const heimdallData = {
      name: this.props.data.allShowsDetailsHeaderBlock.edges[0].node.title,
      subtitle: this.props.data.allShowsDetailsHeaderBlock.edges[0].node.description,
      image: {
        url: this.props.data.allShowsDetailsImageHeaderBlock.edges[0].node.localImage
          .childImageSharp.fluid,
        alt: this.props.data.allShowsDetailsImageHeaderBlock.edges[0].node.alt,
      },
      imageMobile: {
        url: this.props.data.allShowsDetailsImageBlock.edges[0].node.localImage.childImageSharp
          .fluid,
      },
    };

    const buttons = {
      cta: this.props.data.allShowsDetailsCta.edges[0].node.cta,
      size: 'alone',
      color: 'orange',
      ctaText: this.props.data.allShowsDetailsCta.edges[0].node.ctaText,
    };

    const prepareForLoki = () => ({
      title: tt('GALERIA DE FOTOS', this.props.pageContext.locale),
      arrayImg: this.props.data.allShowsDetailsLokiImages.edges
        .map((block) => ({
          altImage: block.node.alt,
          img: block.node.localImage ? block.node.localImage.childImageSharp.fluid : null,
        }))
        .filter((item) => item.img !== null),
      bigArrayImg: this.props.data.bigLokiPics.edges
        .map((block) => ({
          altImage: block.node.alt,
          img: block.node.localImage ? block.node.localImage.childImageSharp.fluid : null,
        }))
        .filter((item) => item.img !== null),
    });

    const PrepareforFreya = (src) => ({
      cards: src.edges
        .map((e) => ({
          image: e.node.localImage ? e.node.localImage.childImageSharp.fluid : undefined,
          altImage: e.node.image_alt,
          imgsize: e.node.imgsize,
          title: e.node.title,
          buttons: e.node.buttons,
        }))
        .filter((e) => e.image !== undefined),
    });

    const PrepareforMimir = (src) => {
      const exclusiveFilter = src.edges.filter((element) => element.node.superior !== 'false');
      const getExclusive = src.edges.filter((element) => element.node.icon === 'ReducedMobility');
      if (getExclusive.length) {
        return {
          icons: exclusiveFilter.map((e) => ({
            icon: e.node.icon,
            text: e.node.text,
          })),
          exclusive: getExclusive.map((e) => ({
            icon: e.node.icon,
            text: e.node.text,
          })),
        };
      }
      return {
        icons: exclusiveFilter.map((e) => ({
          icon: e.node.icon,
          text: e.node.text,
        })),
      };
    };

    const sagaData = {
      image: this.props.data.allShowsDetailsCta.edges[0].node.image,
      altImage: this.props.data.allShowsDetailsCta.edges[0].node.image_alt,
      title: this.props.data.allShowsDetailsSagaContent.edges[0].node.title,
      summary: this.props.data.allShowsDetailsSagaContent.edges[0].node.summary,
      summary: this.props.data.allShowsDetailsSagaContent.edges[0].node.summary,
      video: this.props.data.allShowsDetailsVideo
        ? this.props.data.allShowsDetailsVideo.edges[0].node.video
        : '',
      locale: this.props.pageContext.locale,
      pageContext: this.props.pageContext,
      social: false,
      button1: {
        cta: this.props.data.allShowsDetailsCta.edges[0].node.cta,
        size: 'alone',
        color: 'orange',
        ctaText: this.props.data.allShowsDetailsCta.edges[0].node.ctaText,
      },
      icons: this.props.data.allShowsDetailsMimirIcons.edges
        .filter((element) => element.node.inferior !== 'false')
        .map((e) => ({
          icon: e.node.icon,
          text: e.node.text,
          text2: e.node.text2,
          text3: e.node.text3,
          text4: e.node.text4,
        })),
      filePDF: tt(
        'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/documents/documents/000/013/593/original/horarios_halloween_es_2020_ext._%281%29.pdf',
        this.props.pageContext.locale
      ),
      pageContext: this.props.pageContext,
    };

    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
        options={this.props.pageContext.options}
      >
        <SEO
          title={this.props.data.allShowsDetailsSeoData.edges[0].node._0.title}
          description={this.props.data.allShowsDetailsSeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          imageRich={this.props.data.allShowsDetailsRichData.edges[0].node.rich_markup.imgRich}
          lang={this.props.pageContext.locale}
          disabled={this.props.pageContext.show_name === 'shrek'}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="espectaculos-details-content">
              <Mimir data={PrepareforMimir(this.props.data.allShowsDetailsMimirIcons)} />
              <div className="top-espectaculos-details">
                <NeoAesir
                  locale={this.props.pageContext.locale}
                  pageName={
                    this.props.pageContext.show_name === 'abby-star-studio' ||
                    this.props.pageContext.show_name === 'casa-de-woody'
                      ? 'ficha_espectaculos_as_tiendas'
                      : this.props.pageContext.pageName
                  }
                  options={this.props.pageContext.options}
                  title={this.props.data.allShowsDetailsBreadCrumbBlock.edges[0].node.name}
                />
                <Hod
                  hardcoded={
                    this.props.data.allShowsDetailsRich.edges.length &&
                    this.props.data.allShowsDetailsRich.edges[0].node.richmark
                  }
                  data={this.props.data.allShowsDetailsRichData.edges[0].node.rich_markup}
                  url={this.props.pageContext.url}
                  img={this.props.data.allShowsDetailsRichData.edges[0].node.imgRich}
                />
                <Buttons
                  link={buttons.cta}
                  size={buttons.size}
                  color={buttons.color}
                  text={buttons.ctaText}
                />
              </div>
              <BlueArrow />
              <div className="espectaculos-arrows-content">
                <MagniLeft data={this.props.data.allShowsDetailsMagniBlock.edges[0].node} />
                <MagniRight data={this.props.data.allShowsDetailsMagniBlock.edges[0].node} />
              </div>
              <Saga data={sagaData} />
              {this.props.pageContext.show_name !== 'apocalipsis-maya' && (
                <div className="loki-content">
                  {this.props.data.allShowsDetailsLokiImages.edges.length > 1 &&
                  this.props.data.allShowsDetailsLokiImages ? (
                    <H3 titleAlone={tt('GALERIA DE FOTOS', this.props.pageContext.locale)} />
                  ) : null}
                  {this.props.data.allShowsDetailsLokiImages.edges.length > 1 &&
                  this.props.data.allShowsDetailsLokiImages ? (
                    <Loki data={prepareForLoki()} />
                  ) : null}
                </div>
              )}
              {['aves-del-paraiso', 'bang-bang-west', 'tex-mex'].includes(
                this.props.pageContext.show_name
              ) ? null : (
                <div className="promotion-content">
                  {this.props.pageContext.options[0] === 'ferrari-land' ? (
                    <H3 titleAlone={tt('NO TE PIERDAS...', this.props.pageContext.locale)} />
                  ) : this.props.pageContext.show_name === 'casa-de-woody' ||
                    this.props.pageContext.show_name === 'abby-star-studio' ? (
                    <H3 titleAlone={tt('EXPLORA OTRAS TIENDAS', this.props.pageContext.locale)} />
                  ) : (
                    <H3
                      titleAlone={tt('EXPLORA OTROS ESPECTÁCULOS', this.props.pageContext.locale)}
                    />
                  )}
                  <Freya data={PrepareforFreya(this.props.data.allShowsDetailsFreya)} />
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default showDetails;
